/**
 * DataLayer - Start_filling event
 * @description Клик по любому полю на форме (включая формы в калькуляторе) генерируется событие ' Start_filling'.
 *
 * @example DLFormStartEvent("calculator")
 *
 * @default event: "Start_filling"
 * @param placement: Расположение в форме "main - основная форма" или "calculator - форма в калькуляторе"
 * @param eventLabel: Страница на которой произошло событие
 */

/**
 * DataLayer - applicationSend event
 * @description Если отправляется любая заявка (на любой посадочной), генерируется событие 'applicationSend'.
 *
 * @example DLFormSendEvent({ result: "error", reason: "reject", placement: "calculator", eventLabel?: globalThis?.location?.href }) --- ERROR VARIANT
 * @example DLFormSendEvent({ result: "success", placement: "calculator" , eventLabel?: globalThis?.location?.href}) --- SUCCESS VARIANT
 *
 * @default event: "applicationSend"
 * @param result: Ответ при отправки формы
 * @param reason: Только в случае ошибки, указывается причина отказа
 * @param placement: Расположение в форме "main - основная форма" или "calculator - форма в калькуляторе"
 * @param eventLabel: Страница на которой произошло событие
 */

import { pushToDL } from "../utils/helper"
import type { TDLFormPlacement, TDLPageURL } from "./type"

type TDLApplicationReason =
  | "young" // Не прошел по минимальному возрасту
  | "old" // Не прошел по максимальному возрасту
  | "repeated" // Дубль
  | "reject" // Отказ
  | "blacklist" // Черный список
  | "internal_check_failed" // Не прошел иную внутреннюю проверку (на Контролере и/или скоринге)

type TSendSuccess = {
  result: "success"
  placement: TDLFormPlacement
  eventLabel?: TDLPageURL
}

type TSendError = {
  result: "error"
  reason: TDLApplicationReason
  placement: TDLFormPlacement
  eventLabel?: TDLPageURL
}

type TDLStartFilling = {
  placement: TDLFormPlacement
  eventLabel?: TDLPageURL
}

export const DLFormStartEvent = (() => {
  const alreadyExists: TDLFormPlacement[] = []

  return ({ placement, eventLabel = globalThis?.location?.href }: TDLStartFilling) => {
    if (!alreadyExists.includes(placement)) {
      alreadyExists.push(placement)
      pushToDL({ event: "Start_filling", placement, eventLabel })
    }
  }
})()

export const DLFormSendEvent = (formStatus: TSendSuccess | TSendError) => {
  const defaultEventLabel = globalThis?.location?.href
  if ("reason" in formStatus) {
    pushToDL({
      event: "applicationSend",
      result: formStatus.result,
      reason: formStatus.reason,
      placement: formStatus.placement,
      eventLabel: formStatus.eventLabel ?? defaultEventLabel,
    })
  } else {
    pushToDL({
      event: "applicationSend",
      result: formStatus.result,
      placement: formStatus.placement,
      eventLabel: formStatus.eventLabel ?? defaultEventLabel,
    })
  }
}
