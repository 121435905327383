import React from "react"

import IconVK from "@ecom/ui/components/Footer/SocialLinks/IconVK"
import IconOK from "@ecom/ui/components/Footer/SocialLinks/IconOK"
import IconTelegram from "@ecom/ui/components/Footer/SocialLinks/IconTelegram"

export const defaultCopyright = (
  <>
    ПАО «Совкомбанк». Генеральная лицензия Банка России №963 от 05 декабря 2014г., г. Кострома, пр.
    Текстильщиков, д. 46. г. Москва, Краснопресненская наб., д.14, стр.1, ОГРН 1144400000425
  </>
)

export const defaultSocialLinks = [
  {
    href: "https://hva.im/por6NJ",
    Icon: IconVK,
  },
  {
    href: "https://hva.im/BKwYQO",
    Icon: IconOK,
  },
  {
    href: "https://scb.im/telegram",
    Icon: IconTelegram,
  },
]
