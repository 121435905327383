/**
 * DataLayer - Error event
 * @description Когда пользователь видит экран "Страница не найдена" (404 страница), срабатывает событие "ErrorEvent"
 *
 * @example DLErrorEvent()
 *
 * @default event: "ErrorEvent"
 * @default eventCategory: "error"
 * @default eventAction: "404"
 * @default placement: ""
 * @default eventLabel: Страница на которой произошло событие
 *
 */

import { pushToDL } from "../utils/helper"

export const DLErrorEvent = () => {
  pushToDL({
    event: "ErrorEvent",
    eventCategory: "error",
    eventAction: "404",
    eventLabel: globalThis?.location?.href,
    placement: "",
  })
}
